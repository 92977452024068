import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
import { Text, CircularProgress } from "@chakra-ui/react";
import styled from "styled-components";

import flowers from "../../_assets/flowers.png";
import { useFetchInvitation, useUpdateRSVP } from "../../api";
import { useAssetsLoading } from "../../assetsLoader";
import {
  useChangeTranslationLanguage,
  useTranslation,
} from "../../translations";
import { Img, TransitionIn } from "../../components";
import { useResponsiveSizes } from "../../responsive";

import { NotFound } from "../NotFound";

import { Welcome } from "./stepComponents/Welcome";
import { Confirm } from "./stepComponents/Confirm";
import { Thanks } from "./stepComponents/Thanks";
import { SeeYa } from "./stepComponents/SeeYa";
import { FormValues, STEP } from "./types";

export const RSVPRoute = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}/:code`}>
        <RSVP />
      </Route>
      <NotFound />
    </Switch>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60vw;
  align-self: center;
  margin: auto;
  text-align: center;
`;

const RSVP = () => {
  const [formStep, setFormStep] = useState<STEP>("loading");
  const [formValues, setFormValues] = useState<FormValues>({
    rsvp: "AWAITING",
    comments: "",
  });
  const changeLanguage = useChangeTranslationLanguage();
  const t = useTranslation();
  const { subHeadingSize } = useResponsiveSizes();

  const { code } = useParams<{ code: string }>();
  const { id, key } = decode(code);
  const {
    loading,
    data,
    error,
    response: { status },
  } = useFetchInvitation(id, key);
  const updateRSVP = useUpdateRSVP(id, key);
  const { requestedAssetsAreLoaded } = useAssetsLoading([], [flowers]);

  useEffect(() => {
    const { rsvp } = data?.invitation ?? {};

    if (data?.invitation?.language) {
      changeLanguage(data?.invitation?.language);
    }

    switch (rsvp) {
      case "YES":
        return setFormStep("confirmed_yep");
      case "NO":
        return setFormStep("confirmed_nope");
      default:
        return setFormStep("welcome");
    }
  }, [data, changeLanguage]);

  if (loading || !requestedAssetsAreLoaded) {
    return (
      <Wrapper>
        <CircularProgress
          isIndeterminate
          color="teal.500"
          size="100px"
          thickness="4px"
        />
      </Wrapper>
    );
  }

  const { invitation } = data ?? {};

  if (status === 404) {
    return <NotFound />;
  }

  if (error || !invitation) {
    return (
      <Wrapper>
        <Text fontSize={subHeadingSize}>{t("tryAgain")}</Text>
      </Wrapper>
    );
  }

  return (
    <>
      <TransitionIn>
        <Img src={flowers} alt="flowers" top left widthSize={40} />
        <Img src={flowers} alt="flowers" bottom right rotated widthSize={40} />
      </TransitionIn>
      <Wrapper>
        {formStep === "welcome" && (
          <Welcome
            formValues={formValues}
            setFormValues={setFormValues}
            setFormStep={setFormStep}
            invitation={invitation}
            updateRSVP={updateRSVP}
          />
        )}
        {formStep === "confirmation_details" && (
          <Confirm
            formValues={formValues}
            setFormValues={setFormValues}
            setFormStep={setFormStep}
            invitation={invitation}
            updateRSVP={updateRSVP}
          />
        )}
        {formStep === "confirmed_yep" && <SeeYa />}
        {formStep === "confirmed_nope" && <Thanks />}
      </Wrapper>
    </>
  );
};

const decode = (code: string) => {
  try {
    const { id, key } = JSON.parse(atob(code));
    return { id, key };
  } catch (e) {
    return { id: null, key: null };
  }
};
