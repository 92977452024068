import React from "react";
import { Text } from "@chakra-ui/react";

import { TransitionIn } from "../../../components";
import { useTranslation } from "../../../translations";
import { useResponsiveSizes } from "../../../responsive";

export const Thanks = () => {
  const t = useTranslation();
  const { headingSize, subHeadingSize } = useResponsiveSizes();

  return (
    <TransitionIn>
      <Text fontSize={subHeadingSize} color="gold">
        {t("thanks")}
        <Text fontSize={headingSize} color="gold">
          😔
        </Text>
      </Text>
    </TransitionIn>
  );
};
