import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import WebFontLoader from "webfontloader";

import flowers from "../_assets/flowers.png";
import us from "../_assets/us.jpg";
import emiybeto from "../_assets/emiybeto.mp4";

const AssetsWrapper = styled.div`
  overflow: hidden;
  width: 0px;
  position: absolute;
  top: 0px;
  left: -10000px;
`;

const IMG_ASSETS = [flowers, us];
const INITIAL_IMGS_LOADED = Object.fromEntries(
  IMG_ASSETS.map((img) => [img, false])
);
const FONT_ASSETS = ["Allura", "Josefin Slab"];
const INITIAL_FONTS_LOADED = Object.fromEntries(
  FONT_ASSETS.map((font) => [font, false])
);
const VIDEO_ASSETS = [emiybeto];
const INITIAL_VIDS_LOADED = Object.fromEntries(
  VIDEO_ASSETS.map((vid) => [vid, false])
);

type Setter = React.Dispatch<
  React.SetStateAction<{
    [k: string]: boolean;
  }>
>;
const AssetsLoadingContext = React.createContext({
  imgsLoaded: INITIAL_IMGS_LOADED,
  fontsLoaded: INITIAL_FONTS_LOADED,
  vidsLoaded: INITIAL_VIDS_LOADED,
  setImgsToLoad: (() => void 0) as Setter,
  setVidsToLoad: (() => void 0) as Setter,
});

export const useAssetsLoading = (vids: string[], imgs: string[]) => {
  const {
    imgsLoaded,
    fontsLoaded,
    vidsLoaded,
    setImgsToLoad,
    setVidsToLoad,
  } = useContext(AssetsLoadingContext);

  const vidsString = vids.toString();
  useEffect(() => {
    setVidsToLoad((prevVids) => {
      const newVids = {
        ...prevVids,
      };

      for (const vid of vidsString.split(",")) {
        newVids[vid] = true;
      }
      return newVids;
    });
  }, [vidsString, setVidsToLoad]);

  const imgsString = imgs.toString();
  useEffect(() => {
    setImgsToLoad((prevImgs) => {
      const newImgs = {
        ...prevImgs,
      };

      for (const vid of imgsString.split(",")) {
        newImgs[vid] = true;
      }
      return newImgs;
    });
  }, [imgsString, setImgsToLoad]);

  const allFontsAreLoaded = Object.keys(fontsLoaded).every(
    (key) => fontsLoaded[key]
  );

  const requestedAssetsAreLoaded =
    allFontsAreLoaded &&
    imgs.every((img) => imgsLoaded[img]) &&
    vids.every((vid) => vidsLoaded[vid]);

  return { requestedAssetsAreLoaded, imgsLoaded, fontsLoaded, vidsLoaded };
};

export const AssetsLoaderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [fontsLoaded, setFontsLoaded] = useState(INITIAL_FONTS_LOADED);
  const [imgsLoaded, setImgsLoaded] = useState(INITIAL_IMGS_LOADED);
  const [vidsLoaded, setVidsLoaded] = useState(INITIAL_VIDS_LOADED);

  const [imgsToLoad, setImgsToLoad] = useState(INITIAL_IMGS_LOADED);
  const [vidsToLoad, setVidsToLoad] = useState(INITIAL_VIDS_LOADED);

  useEffect(() => {
    WebFontLoader.load({
      google: {
        families: FONT_ASSETS,
      },
      active: () => {
        setFontsLoaded(
          Object.fromEntries(FONT_ASSETS.map((font) => [font, true]))
        );
      },
    });
  }, [setFontsLoaded]);

  const onImgLoaded = (loadedImg: string) => () => {
    setImgsLoaded((prevImgsLoaded) => ({
      ...prevImgsLoaded,
      [loadedImg]: true,
    }));
  };

  const onVidLoaded = (loadedVid: string) => () => {
    setVidsLoaded((prevVidsLoaded) => ({
      ...prevVidsLoaded,
      [loadedVid]: true,
    }));
  };

  const imgsToPreload = IMG_ASSETS.filter(
    (img) => imgsToLoad[img] && !imgsLoaded[img]
  );
  const vidsToPreload = VIDEO_ASSETS.filter(
    (vid) => vidsToLoad[vid] && !vidsLoaded[vid]
  );

  return (
    <AssetsLoadingContext.Provider
      value={{
        imgsLoaded,
        fontsLoaded,
        vidsLoaded,
        setImgsToLoad,
        setVidsToLoad,
      }}
    >
      <AssetsWrapper>
        {imgsToPreload.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={img}
            onLoad={onImgLoaded(img)}
            width={0}
          />
        ))}
        {vidsToPreload.map((vid, index) => (
          <video
            key={index}
            autoPlay={true}
            src={vid}
            onCanPlayThrough={onVidLoaded(vid)}
            muted
          />
        ))}
      </AssetsWrapper>
      {children}
    </AssetsLoadingContext.Provider>
  );
};
