import React, { useState } from "react";
import {
  Text,
  Button,
  useToast,
  Link,
  VStack,
  useDisclosure,
  Textarea,
  Collapse,
} from "@chakra-ui/react";

import { RSVPAPIResponseBody } from "../../../types";
import { useUpdateRSVP } from "../../../api";
import { useTranslation } from "../../../translations";
import { Modal, ButtonWrapper, TransitionIn } from "../../../components";
import { useResponsiveSizes } from "../../../responsive";

import { FormValues, STEP } from "../types";

export const Welcome = ({
  formValues,
  setFormValues,
  setFormStep,
  invitation,
  updateRSVP,
}: {
  formValues: FormValues;
  setFormValues: (
    setter: (previousFormValues: FormValues) => FormValues
  ) => void;
  setFormStep: (step: STEP) => void;
  invitation: RSVPAPIResponseBody["get"]["invitation"];
  updateRSVP: ReturnType<typeof useUpdateRSVP>;
}) => {
  const toast = useToast();
  const t = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { name } = invitation ?? {};
  const { headingSize, subHeadingSize, buttonSize } = useResponsiveSizes();
  const [comments, setComments] = useState("");
  const [show, setShow] = useState(false);

  console.log(buttonSize);

  const submitForm = async () => {
    const { success } = await updateRSVP({
      rsvp: "NO",
      numOfAdults: 0,
      numOfKids: 0,
      comments,
    });

    if (!success) {
      throw new Error("Update not successful :/");
    }
  };

  return (
    <TransitionIn>
      <Modal
        content={
          <VStack>
            <Text>
              {t("youAreConfirming")}
              <b>{t("no")}</b>
              {t("willMakeIt")}
            </Text>
            <Button size="sm" onClick={() => setShow((pv) => !pv)}>
              {t("leaveAComment")}
            </Button>
            <Collapse in={show} style={{ width: "100%" }}>
              <Textarea
                value={comments}
                onChange={({ target }) =>
                  setComments(target.value.slice(0, 800))
                }
              />
            </Collapse>
          </VStack>
        }
        confirmText={t("confirm")}
        abortText={t("cancel")}
        onClose={async () => {
          try {
            await submitForm();
            setFormStep("confirmed_nope");
            onClose();
          } catch (_e) {
            toast({
              title: "Error",
              description: t("tryAgain"),
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        }}
        onAbort={onClose}
        isOpen={isOpen}
      />
      <VStack spacing="30px">
        <Text fontSize={headingSize} color="gold" fontFamily="Allura">
          {name}
        </Text>
        <Text fontSize={subHeadingSize} color="gold">
          {t("ourWedding")}
          <Link
            color="teal.500"
            href="https://www.hotelcastellemporda.com/"
            isExternal
          >
            {"Castell d’Empordà"}
          </Link>
        </Text>
      </VStack>
      <ButtonWrapper>
        <Button
          size={buttonSize}
          onClick={() => {
            setFormValues((previousValues) => ({
              ...previousValues,
              rsvp: "YES",
            }));
            setFormStep("confirmation_details");
          }}
        >
          {t("yesICan")}
        </Button>

        <Button size={buttonSize} onClick={onOpen}>
          {t("noICant")}
        </Button>
      </ButtonWrapper>
    </TransitionIn>
  );
};
